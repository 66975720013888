* {
  // box-shadow: 0 0 1px 0 red;
}

section.banner {
  margin-top: 30px;
  .container.slide {
    margin: 0 auto;
    height: 443px;
    background-position: center;
    background-size: cover;
    position: relative;
    .swiper-container {
      height: 100%;
      .swiper-wrapper {
        .swiper-slide {
          .wrapper-image {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
          }
        }
      }
    }

    .play-and-pause {
      position: absolute;
      bottom: 30px;
      right: 40px;
      width: 86px;
      background: #dddddd;
      border-radius: 12px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      z-index: 9;
      img {
        background-image: none !important;
        width: 7px;
        vertical-align: middle;
        cursor: pointer;
        height: auto !important;
        position: absolute;
        margin-top: 0 !important;
        &:first-child {
          float: left;
          // margin-top: 10.3px;
          // margin-top: -3px;
          top: calc(50% - 4px) !important;
        }
        &:last-child {
          float: right;
          // margin-top: 10.3px;
          // margin-top: -3px;
          top: calc(50% - 4px) !important;
        }
        &.pause {
          top: calc(50% - 4px) !important;
          left: calc(50% - 3.5px);
        }
      }
    }
  } // .container.slide
} // .banner

section.below-slide {
  background: #e8e8e8;
  .container.slide-bottom {
    margin: 0 auto;
    background: #fff;
    // height: 146px;
    overflow: hidden;
    .col {
      $border_color: #c7c7c7;
      width: 25%;
      height: 100%;
      overflow: hidden;
      float: left;
      cursor: pointer;
      border-right: 1px solid $border_color;
      border-bottom: 1px solid $border_color;
      box-sizing: border-box;
      &:first-child {
        border-left: 1px solid $border_color;
      }

      &:nth-child(1) {
        .top-fill {
          background: #306cb2;
        }

        .pictogram {
          width: 66px;
        }
      }
      &:nth-child(2) {
        .top-fill {
          background: #54b480;
        }
        .pictogram {
          width: 50px;
        }
      }
      &:nth-child(3) {
        .top-fill {
          background: #f0840d;
        }
        .pictogram {
          width: 50px;
        }
      }
      &:nth-child(4) {
        .top-fill {
          background: #919191;
        }
        .pictogram {
          width: 50px;
        }
      }

      .inner-col {
        height: 100%;
        text-align: center;
        position: relative;
        &:last-child:after {
          content: '';
          height: calc(100% - 12px);
          width: 1px;
          background: #fff;
          display: block;
          position: absolute;
          top: 11px;
          right: 0;
        }
        &:before {
          content: '';
          height: calc(100% - 12px);
          width: 1px;
          background: #fff;
          display: block;
          position: absolute;
          top: 11px;
        }

        &:after {
          right: 0;
        }

        &:hover {
          .default-display {
            display: none;
          }
          .hover {
            display: block;
          }
        }

        .top-fill {
          height: 10px;
        }

        .default-display {
          > img {
            width: 100%;
            height: 136px;
          }

          .wrapper-pictogram {
            $height: 90px;
            height: $height;
            line-height: $height;
            .pictogram {
              vertical-align: middle;
            }
          } // .wrapper-pictogram

          .wrapper-text {
            width: 100%;
            p {
              font-size: 16px;
            }
          }
        } // .default-display
        .hover {
          display: none;
          overflow: hidden;
          img {
            width: 100%;
            height: 136px;
          }
        }
      } // .inner-col
    } // .col
  } // .container.slide-bottom

  .container.test-council-contact {
    $border_color: #c7c7c7;
    margin: 0 auto;
    .col {
      width: 33.3333%;
      float: left;
      padding: 10px;
      height: 266px;
      box-sizing: border-box;
      .inner-col {
        width: 100%;
        height: 100%;
        border: 1px solid $border_color;
        background: #e9f4ee;
        padding: 30px;
        padding-top: 20px;
        box-sizing: border-box;
        position: relative;

        p.title {
          font-size: 24px;
          color: #003c56;
          font-weight: bold;
        }
        span.description {
          font-size: 14px;
          color: #09666d;
          margin-top: 5px;
          display: inline-block;
        }

        ul {
          margin-top: 10px;
          li {
            font-size: 12px;
            position: relative;
            margin-bottom: 10px;
            &:before {
              content: '∙';
              position: absolute;
              left: -10px;
            }
            p {
              color: #09666d;
              font-weight: bold;
              margin-bottom: 3px;
              font-size: 13px;
            }
            span {
              color: #494949;
              font-size: 13px;
            }
          }
        }

        .wrapper-buttons {
          display: flex;
          justify-content: space-around;
          margin-left: -15px;
          margin-right: -15px;
          width: calc(100% + 30px);
          margin-top: 20px;
          button {
            background: #5fd683;
            border-radius: 8px;
            border-width: 3px;
            border-top-width: 0;
            border-right-width: 0px;
            border-left-width: 0px;
            border-color: #3dc15c;
            color: #003c56;
            cursor: pointer;
            font-weight: 400;
            width: 110px;
            box-sizing: border-box;
            padding: 2px 0;
            &:nth-child(2),
            &:nth-child(3) {
              background: #5da6d8;
              border-color: #3b81c3;
              color: #fff;
            }

            &:focus {
              outline: none;
            }
            &:active {
              border-width: 0;
            }
            a {
              width: 100%;
              padding: 3px 0;
            }
          }
        } // .wrapper-buttons

        &.test {
          img.main-image {
            width: 80px;
            position: absolute;
            right: 30px;
            top: 35px;
          }
        } // .test

        &.council {
          img {
            width: 80px;
            position: absolute;
            right: 30px;
            top: 35px;
          }

          .wrapper-buttons {
            justify-content: flex-end;
            button {
              background: #5da6d8;
              border-color: #3b81c3;
              color: #fff;
              margin-left: 10px;
            }
          }
        } // .council

        &.contact {
          background: transparent;
          padding: 0;
          border: 0;
          .row.tell {
            padding: 20px;
            border: 1px solid  $border_color;
            height: 135px;
            background: #00637a;
            color: #fff;
            position: relative;
            .bg-img {
              position: absolute;
              right: 0;
              top: 0;
              width: 160px;
            }
            h3 {
              font-size: 24px;
              font-weight: 400;
            }

            p.first {
              font-size: 12px;
              font-weight: 200;
              margin-top: 5px;
            }

            p.second {
              font-size: 38px;
              margin-top: 10px;
              font-weight: 200;
              letter-spacing: .1rem;
              span {
                letter-spacing: -.14rem;
              }
            }

            p.third {
              font-weight: 200;
              font-size: 12px;
              margin-top: 8px;
            }
          }
          .row.direct {
            background: #20a1af;
            margin-top: 10px;
            height: 58px;
            padding: 10px;
            box-sizing: border-box;
            .inner-row {
              background: #fff;
              width: calc(100% - 40px);
              height: 100%;
              line-height: 38px;
              padding-left: 10px;
              box-sizing: border-box;
              float: left;
              img {
                width: 30px;
                vertical-align: middle;
              }

              span {
                margin-left: 5px;
                color: #4f4f4f;
              }
            } // end .inner-row
            .right-arrow {
              width: 25px;
              margin-top: 8.7px;
              margin-left: 10px;
            }
          } // end .row.direct
        } // .contact
      } // .inner-col
    } // .col
  } // .test-council-contact
} // .below-slide

section.notice-and-community {
  background: #e8e8e8;
  padding-bottom: 30px;

  .container {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    border: 1px solid #c7c7c7;
    box-sizing: border-box;

    .col {
      float: left;
      width: 50%;
      padding: 10px;
      box-sizing: border-box;
      padding: 0 30px;
      h2 {
        img {
          float: right;
          width: 20px;
          cursor: pointer;
        }
      }

      table {
        width: 100%;
        margin-top: 20px;
        $font_size: 14px;
        td {
          padding: 3px 0;
          font-weight: 200;
          font-size: $font_size;
        }

        td.subject {
          a:hover {
            text-decoration: underline;
          }
        }

        td.date {
          color: #7f7f7f;
          text-align: right;
        }
      }
    }
  }
} // end .notice-and-community

aside.bottom-aside {
  background-color: #5e5e5e;
  $height: 50px;
  .container {
    margin-left: auto;
    margin-right: auto;
    line-height: $height;

    .wrapper-ul {
      ul:first-child {
        float: left;
        li {
          float: left;
          padding: 0 10px;
          a {
            color: #fff;
            font-weight: 200;
            &.active,
            &:hover {
              color: #ffe000;
            }
          }
        }
      }

      ul:last-child {
        float: right;
        li {
          float: left;
          padding: 0 3px;
          a {
            img {
              width: 25px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
} // end .bottom-aside

@media only screen and (max-width: 1200px) {
  footer .wrapper-playAndPause {
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 1020px) {
  .full-width {
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .content-width {
    width: 100%;
  }

  section.notice-and-community .container {
    width: calc(100% - 20px);
  }
}

@media only screen and (max-width: 768px) {
  section.banner {
    margin-top: 0;
  }

  nav.main .container .col.logo {
    text-align: center;
    padding-bottom: 20px;
  }

  .test-council-contact {
    .col {
      width: 100% !important;
    }
  }

  section.notice-and-community .container .col {
    width: 100% !important;
    &:last-child {
      padding-top: 30px;
    }
  }

  aside.bottom-aside .container .wrapper-ul ul:last-child {
    float: left;
    padding-left: 10px;
  }

  nav.main .container .col {
    width: 100% !important;
    &.menus {
      height: auto !important;
      a {
        float: left;
        width: 50%;
        padding-left: 0;
        text-align: center;
        height: auto;
        font-size: 18px;
        &:hover {
          background-color: #95cfee;
          color: #fff;
        }
      }
    }
  }

  aside.top .container {
    text-align: left;
  }

  section.banner .container.slide {
    height: 300px;
  }
}

@media only screen and (max-width: 760px) {
  section.below-slide .container.slide-bottom .col {
    width: 50%;
  }
}

@media only screen and (max-width: 440px) {
  section.below-slide {
    padding-top: 10px;
  }

  section.below-slide .container.slide-bottom {
    height: auto !important;
    width: calc(100% - 20px);
  }

  section.below-slide .container.slide-bottom .col {
    width: 100%;
  }

  footer .container .col {
    width: 100% !important;
    &.logo {
      text-align: center;
    }
  }

  aside.bottom-aside .container {
    width: calc(100% - 20px);
  }

  aside.bottom-aside .container .wrapper-ul ul:first-child li {
    width: 100%;
  }

  footer .container .col.detail {
    padding-left: 10px;
  }

  footer .container .col.logo img {
    width: 60px;
  }

  section.below-slide .container.slide-bottom .col .inner-col img {
    height: auto !important;
  }

  section.below-slide .container.test-council-contact .col .inner-col .wrapper-buttons button {
    width: 90px;
  }
  
  section.below-slide .container.test-council-contact .col .inner-col .wrapper-buttons button a {
    font-size: 10px;
  }
}
